<template>
    <n-list value="discounts" text="折扣" :fields="fields" :items="discounts" />
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            fields: [
                {
                    key: 'code',
                    label: '優惠碼',
                    sortable: true,
                },
                {
                    key: 'name',
                    label: '名稱',
                    sortable: true,
                },
                {
                    key: 'type',
                    label: '類型',
                    formatter: value => {
                        switch(value) {
                            case 'AMOUNT':
                                return '全單金額'
                            case 'PERCENTAGE':
                                return '全單百分比'
                            case 'PRODUCT_AMOUNT':
                                return '商品金額'
                            case 'PRODUCT_PERCENTAGE':
                                return '商品百分比'
                        }
                    },
                    sortable: true,
                    class: 'd-none d-sm-table-cell',
                },
                {
                    key: 'amount',
                    label: '數值',
                    formatter: (value, key, item) => {
                        switch(item.type) {
                            case 'AMOUNT':
                                return `$${value}`
                            case 'PERCENTAGE':
                                return `${value}%`
                            case 'PRODUCT_AMOUNT':
                                return `$${value}`
                            case 'PRODUCT_PERCENTAGE':
                                return `${value}%`
                        }
                    },
                    sortable: true,
                },
                {
                    key: 'presentAtShopIds',
                    label: '適用分店',
                    formatter: value => {
                        return `${value.length}間`
                    },
                    sortable: true,
                    class: 'd-none d-sm-table-cell',
                }
            ]
        }
    },

    created() {
        this.$store.dispatch('bindCollectionByMerchant', { col: 'discounts', merchantId: localStorage.merchantId })
    },

    computed: {
        ...mapState({
            discounts: state => state.discounts,
        })
    }
}
</script>